<template>
  <div class="up-success">
    <h1 class="up-success__title up-main-title">
      Курс {{ courseInfo.course ? courseInfo.course.name : "" }} успешно пройден
      !!!
    </h1>
    <p class="up-success__text">
      Вы успешно выполнили все задания. Для того, чтобы посмотре все доступные
      курсы нажмите на кнопку
    </p>
    <router-link class="el-button el-button--success" to="/listener">
      Посмотреть доступные курсы
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CoursePassedSuccessfully",
  data: () => ({
    courseInfo: [],
  }),
  methods: {
    async fetchCourseInfo() {
      try {
        const { data: courseInfo } = await this.$axios.get(
          `/listener/flow/${this.$route.params.id}`
        );

        this.courseInfo = courseInfo;
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchCourseInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/listener/course/success.scss";
</style>